export enum KlassStatus {
  Active = 1,
  TransferWait = 2,
  TransferDone = 3,
  Graduated = 4,
}

export const KLASS_STATUS_LABEL_MAP: Record<KlassStatus, string> = {
  [KlassStatus.Active]: 'Активный',
  [KlassStatus.TransferWait]: 'Ожидает перевода',
  [KlassStatus.TransferDone]: 'Перевод завершен',
  [KlassStatus.Graduated]: 'Класс выпустился',
}
